<template>
  <div ref="dropdownContainer">
    <Icon name='majesticons:dots-vertical' :class="color ? color : 'text-themegray'" size="21" role="button" @click="toggleDropdown()" />
    <div :class="['project-opt absolute right-0 z-[2]', ddClass, !showDropdown && 'hidden']">
      <ul class="list-none p-0 m-0 text-sm font-medium text-themegray bg-white overflow-hidden rounded-md text-start">
        <slot/>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const props = defineProps(['color', 'ddClass'])

const showDropdown = ref(false);
const dropdownContainer = ref(null);

const toggleDropdown = () => {
  showDropdown.value = !showDropdown.value;
}

const handleClickOutside = (event) => {
  if (dropdownContainer.value && !dropdownContainer.value.contains(event.target)) {
    showDropdown.value = false;
  }
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>

<style></style>